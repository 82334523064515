// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-course-js": () => import("/opt/build/repo/src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-course-alternative-js": () => import("/opt/build/repo/src/templates/course-alternative.js" /* webpackChunkName: "component---src-templates-course-alternative-js" */),
  "component---src-templates-online-course-js": () => import("/opt/build/repo/src/templates/online-course.js" /* webpackChunkName: "component---src-templates-online-course-js" */),
  "component---src-templates-article-js": () => import("/opt/build/repo/src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fi-excel-koulutus-js": () => import("/opt/build/repo/src/pages/fi/excel-koulutus.js" /* webpackChunkName: "component---src-pages-fi-excel-koulutus-js" */),
  "component---src-pages-fi-excel-verkkokurssit-js": () => import("/opt/build/repo/src/pages/fi/excel-verkkokurssit.js" /* webpackChunkName: "component---src-pages-fi-excel-verkkokurssit-js" */),
  "component---src-pages-fi-konsultointi-js": () => import("/opt/build/repo/src/pages/fi/konsultointi.js" /* webpackChunkName: "component---src-pages-fi-konsultointi-js" */),
  "component---src-pages-fi-office-koulutukset-js": () => import("/opt/build/repo/src/pages/fi/office-koulutukset.js" /* webpackChunkName: "component---src-pages-fi-office-koulutukset-js" */),
  "component---src-pages-fi-yhteystiedot-js": () => import("/opt/build/repo/src/pages/fi/yhteystiedot.js" /* webpackChunkName: "component---src-pages-fi-yhteystiedot-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laskutus-ja-peruutusehdot-js": () => import("/opt/build/repo/src/pages/laskutus-ja-peruutusehdot.js" /* webpackChunkName: "component---src-pages-laskutus-ja-peruutusehdot-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

